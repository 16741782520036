import { Link as RouterLink } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Box, Link, Container, Typography, Stack, Grid } from '@mui/material';
// layouts 
// components
import Page from '../components/Page';
import { RegisterForm } from '../sections/authentication/register';


// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const SectionStyle = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  // margin: theme.spacing(2, 0, 2, 10),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 680,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(8, 0),
}));

// ----------------------------------------------------------------------

export default function Register() {
  return (
    <RootStyle title='Register | KnoCard'>
      <Grid
        container
        display={'flex'}
        direction={'row'}
        justifyContent={'center'}
        spacing={2}
      >
        <Grid item alignSelf={'center'}>
          <SectionStyle sx={{ display: { xs: 'none', md: 'flex' } }}>
            <img
              alt='register'
              src='/static/illustrations/illustration_register.png'
            />
          </SectionStyle>
        </Grid>

        <Grid item md={6}>
          <Container>
            <ContentStyle>


              <RegisterForm />

              <Stack
                direction={{ xs: 'column', sm: 'row' }}
                spacing={2}
                justifyContent={'center'}
              >
                <Typography
                  variant='subtitle2'
                  color={'grey'}
                  sx={{
                    mt: 3,
                    textAlign: 'center',
                    width: '100%',
                  }}
                >
                  Already a member?&nbsp;
                  <Link underline='hover' to='/login' component={RouterLink}>
                    Sign In
                  </Link>
                </Typography>
                <Box width={'100%'} display={{ xs: 'none', sm: 'block' }} />
              </Stack>
            </ContentStyle>
          </Container>
        </Grid>
      </Grid>
    </RootStyle>
  );
}
