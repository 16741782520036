import { configureStore } from '@reduxjs/toolkit';
import authReducer from './auth/authSlice';
import registerReducer from './register/registerSlice';
import profileReducer from './profile/profileSlice';
import selectAffiliateGroupReducer from './selectAffiliateGroup/selectAffiliateGroupSlice';
import selectAffiliateGroupStateReducer from './selectAffiliateGroupState/selectAffiliateGroupStateSlice';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

const store = configureStore({
  reducer: {
    auth: authReducer,
    register: registerReducer,
    profile: profileReducer,
    selectAffiliateGroup: selectAffiliateGroupReducer,
    selectAffiliateGroupState: selectAffiliateGroupStateReducer,
  },
});

export default store;
// export type AppDispatch = typeof store.dispatch;

type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useAppDispatch = () => useDispatch<AppDispatch>();
