import { getItem } from '../../../utils/localStorage';
import { config } from '../../../utils/utils';

export interface UserInfo {
  id: number;
  name: string;
  username: string;
  first_name: string;
  last_name: string;
  email: string;
  email_verified_at: null;
  api_token: string;
  phone_country_code: string;
  mobile_number: null;
  street1: null;
  street2: null;
  city: string;
  state: string;
  zip_code: string;
  profile_picture: string;
  occupation: string;
  tagline: null;
  about_me: null;
  phone_number: string;
  integration_id: null;
  gateway: null;
  card_brand: null;
  card_last_four: null;
  is_affliate: number;
  affliate_verification_token: string;
  role: string;
  created_at: Date;
  updated_at: Date;
  get_started: number;
  meta_tags: string;
  is_legacy: number;
  create_and_share_id: null;
  show_profile_picture: number;
  status: Status;
  go_live_code: null;
  longitude: string;
  latitude: string;
  show_phone_number: number;
  is_face_id: number;
  affliate_firstname: number;
  affliate_lastname: number;
  referece_code: string;
  current_balance: number;
  is_affliate_paid: number;
  affiliate_created_at: Date;
  company: Company;
  knocard: Knocard;
  social_media: SocialMedia[];
  external_pages: ExternalPages;
  photo_galleries: PhotoGallery[];
  collages: Collage[];
  user_features: UserFeature[];
  playlists: Playlist[];
  avg_user_ratings: any[];
  user_connections: any[];
  affiliate_link: string | null;
  purchase_feedback_form: {
    id: number;
    user_id: number;
    created_at: string;
    updated_at: string;
    purchase_code: string;
    server_verification_data: string;
    source: string;
  } | null;
  active_subscription: {
    id: number;
    user_id: number;
    plan_id: number;
    subscription_reference: any;
    trial_ends_at: any;
    ends_at: any;
    status: string;
    gateway: any;
    extras: any;
  } | null;
}

export interface Collage {
  id: number;
  user_id: number;
  title: null;
  description: null;
  meta_tags: null;
  sort_order: number;
  created_at: Date;
  updated_at: Date;
}

export interface Company {
  id: number;
  user_id: number;
  profile_picture: null;
  name: null;
  tagline: null;
  business_phone: string | null;
  business_mobile_phone: string | null;
  fax: null;
  business_email_address: null;
  content: null;
  created_at: Date;
  updated_at: Date;
  lng: string;
  lat: string;
  promotional_video: string;
  icon: number;
  title: string;
  description: string;
  street1: null;
  street2: null;
  city: null;
  state: null;
  zip_code: null;
  icon_url: null;
  thumbnail: string;
  business_page_title: string;
  is_pagetitle_enable: number;
  is_media_enable: number;
  is_description_enable: number;
}

export interface ExternalPages {
  id: number;
  user_id: number;
  name: string;
  link: string;
  icon: string;
  is_selected: number;
  created_at: Date;
  updated_at: Date;
  external_page_files: any[];
}

export interface Knocard {
  id: number;
  user_id: number;
  theme: string;
  background_image?: string;
  about_me: null;
  created_at: Date;
  updated_at: Date;
  favicon: null;
  homepage: string;
}

export interface PhotoGallery {
  id: number;
  user_id: number;
  title: null;
  description: null;
  meta_tags: null;
  collage_id: null;
  sort_order: null;
  created_at: Date;
  updated_at: Date;
  type: string;
  link: string;
  turn_off_commenting: null;
}

export interface Playlist {
  id: number;
  user_id: number;
  name: string;
  status: Status;
  created_at: Date;
  updated_at: Date;
  deleted_at: null;
  is_favorite: null;
  description: string;
  is_default: number;
  videos: Video[];
}

export enum Status {
  Active = 'active',
  Inactive = 'inactive',
}

export interface Video {
  id: number;
  user_id: number;
  platform: Platform;
  link: string;
  status: Status;
  created_at: string;
  updated_at: string;
  deleted_at: null;
  description: string;
  playlist_id: number;
  title: string;
  thumbnail: string;
  sort_order: number | null;
  duration: string;
}

export enum Platform {
  Firebase = 'Firebase',
  Uploaded = 'uploaded',
  Youtube = 'youtube',
}

export interface SocialMedia {
  id: number;
  user_id: number;
  name: string;
  user_handle: null;
  link: string;
  created_at: Date;
  updated_at: Date;
}

export interface UserFeature {
  id: number;
  user_id: number;
  lookup_id: number;
  created_at: Date;
  updated_at: Date;
}

export interface getUserInfoRequestResponse {
  status: string;
  data: {
    user: UserInfo[];
  };
}

export interface getUserInfoFromReferenceCodeRequestResponse {
  status: string;
  data: {
    user: UserInfo;
  };
}

const accessToken = localStorage.getItem(config.AUTH_KEY);
const userInfo: UserInfo | undefined = getItem(config.INFO) ?? undefined;

interface InitialState {
  accessToken: string;
  user: UserInfo | undefined;
  isError: boolean;
  isLoading: boolean;
  errorMessage: string | undefined;
}

export interface Values {
  username: string;
  password: string;
}

export const initialState: InitialState = {
  accessToken: accessToken || '',
  user: userInfo,
  isError: false,
  isLoading: false,
  errorMessage: undefined,
};
