import { createSlice } from '@reduxjs/toolkit';
import { findSelectAffiliateGroupState } from './api';
import { initialState } from './api/interface';

const selectAffiliateGroupStateSlice = createSlice({
  name: 'selectAffiliateGroupState',
  initialState,
  reducers: {
    setStatus: (state, action) => {
      state.status = action.payload.status;
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(findSelectAffiliateGroupState.pending, (state) => {
      state.isLoading = true;
      state.status = 'pending';
    });
    builder.addCase(
      findSelectAffiliateGroupState.fulfilled,
      (state, action) => {
        state.data.push(...action.payload.data.data);
        state.isError = false;
        state.isSuccess = true;
        state.isLoading = false;
        state.status = 'succeeded';
      }
    );
    builder.addCase(findSelectAffiliateGroupState.rejected, (state, _) => {
      state.isLoading = false;
      state.isError = true;
      state.isSuccess = false;
      state.status = 'failed';
    });
  },
});

export const { setStatus, reset } = selectAffiliateGroupStateSlice.actions;
export default selectAffiliateGroupStateSlice.reducer;
