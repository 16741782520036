import CryptoJS from 'crypto-js';
import { config } from './utils';

export const setItem = (key: string, value: any) => {
  const stringValue = CryptoJS.AES.encrypt(
    JSON.stringify(value),
    config.SECRET_KEY
  ).toString();
  localStorage.setItem(key, stringValue);
};

export const getItem = (key: string) => {
  const item = localStorage.getItem(key);
  if (item) {
    try {
      const stringValue = CryptoJS.AES.decrypt(
        item,
        config.SECRET_KEY
      ).toString(CryptoJS.enc.Utf8);
      return JSON.parse(stringValue);
    } catch (error) {
      removeItem(key);
      return '';
    }
  }
  removeItem(key);
  return '';
};

export const removeItem = (key: string) => {
  localStorage.removeItem(key);
};
