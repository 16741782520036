import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import React, { useState } from 'react';
import DropzoneComponent from './DropZoneComponent';
import { Transition } from './Transition';

import { LoadingButton } from '@mui/lab';
import Scrollbar from './Scrollbar';
import { toast } from 'react-toastify';
import { Uploader } from 'uploader';
import { UploadButton } from 'react-uploader';

interface IProps {
  toggle: boolean;
  onClose: () => void;
  setAttachmentArray: React.Dispatch<React.SetStateAction<any>>;
  module: string;
}

interface File {
  path: string;
  name: string;
  preview: string;
  type: string;
  loader?: boolean;
  id?: string;
  retry?: boolean;
}

const UploadAssetsModal: React.FC<IProps> = ({
  toggle,
  onClose,
  setAttachmentArray
}) => {
  const [files] = useState<File[]>([]);

  // Get production API keys from Upload.io
  const uploader = Uploader({
    apiKey: 'free',
  });

  // Customize the file upload UI (see "customization"):
  const options = { multi: false };

  const onSubmitUpload = async () => {
    const AttachmentArray: string[] = [];
    files.forEach((element, index) => {
      if (element.id && element.loader === false) {
        return (AttachmentArray[index] = element.id);
      } else {
        return '';
      }
    });
    if (AttachmentArray?.length === files?.length) {
      setAttachmentArray(AttachmentArray);
      onClose();
    } else {
      toast.error('Please wait until you assets are uploaded');
    }
  };

  return (
    <>
      <Dialog
        fullWidth
        open={toggle}
        TransitionComponent={Transition}
        keepMounted
        onClose={onClose}
        aria-describedby='alert-dialog-slide-description'
      >
        <DialogTitle>{'Drop or Select file'}</DialogTitle>
        <DialogContent>
          <Scrollbar>
            {/* <DropzoneComponent
              setFiles={setFiles}
              files={files}
              module={module}
            /> */}
            <UploadButton
              uploader={uploader} // Required.
              options={options} // Optional.
              onComplete={(files) => {
                // Optional.
                if (files.length === 0) {
                  console.log('No files selected.');
                } else {
                  console.log('Files uploaded:');
                  console.log(files.map((f) => f.fileUrl));
                }
              }}
            >
              {({ onClick }) => (
                <button onClick={onClick}>Upload a file...</button>
              )}
            </UploadButton>
          </Scrollbar>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              onClose();
            }}
            variant='outlined'
          >
            {'Cancel'}
          </Button>
          <LoadingButton variant='contained' onClick={onSubmitUpload}>
            {'Upload'}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UploadAssetsModal;
