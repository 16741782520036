import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Button, { ButtonProps } from '@mui/material/Button';

import { styled } from '@mui/material/styles';
import { red } from '@mui/material/colors';

import Logo from './Logo';
import { logoutUser } from '../store/auth/api';
import { useDispatch } from 'react-redux';

const SignOutButton = styled(Button)<ButtonProps>(({ }) => ({
  //   color: theme.palette.getContrastText(red[600]),
  fontSize: '16px',
  backgroundColor: '#FB082E',
  borderRadius: 30,
  border: '2px solid white',
  paddingLeft: '28px',
  paddingRight: '28px',
  boxShadow:
    '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
  '&:hover': {
    backgroundColor: red[800],
    boxShadow:
      '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
  },
}));

function ResponsiveAppBar() {
  const dispatch = useDispatch();

  const handleSignOut = async (_: React.MouseEvent<HTMLElement>) => {
    localStorage.clear();
    await dispatch(logoutUser());
  };

  return (
    <AppBar position='fixed' sx={{ backgroundColor: 'white' }}>
      <Container maxWidth='xl'>
        <Toolbar disableGutters>
          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            <img src='/static/illustrations/ENTERPRISE.png' />
          </Box>

          <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
            {/* <Icon src='/static/knocard_logo.svg' /> */}
            <Logo />
          </Box>

          <Box sx={{ flexGrow: 0, position: 'absolute', right: 0 }}>
            <SignOutButton onClick={handleSignOut} variant='contained'>
              SIGN OUT
            </SignOutButton>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;
