import React, { useState } from 'react';
import sidebarConfig from './SidebarConfig';

const SidebarNewSample = () => {
  const navLink = document.querySelectorAll('.nav__link');

  function linkColor(e: Event) {
    navLink.forEach((link) => link.classList.remove('active-link'));
    // this.classList.add('active-link');
  }
  navLink.forEach((link) => link.addEventListener('click', linkColor));

  return (
    <>
      <nav className='nav'>
        <ul className='nav__list'>
          {sidebarConfig.map((item) => {
            return (
              <a href={item.path} className='nav__link'>
                <i>
                  <img src={item.icon} alt={item.title} />
                </i>
                <span className='nav__name'>{item.title}</span>
              </a>
            );
          })}
          {/* <a href='#' className='nav__link active-link'>
            <i>
              <img src={'/static/icons/ic_dashboard.png'} alt='Dashboard' />
            </i>
            <span className={'nav__name'}>Dashboard</span>
          </a> */}
        </ul>

        {/* <!-- Shapes --> */}
        <div className='nav__circle-1'></div>
        <div className='nav__circle-2'></div>

        <div className='nav__square-1'></div>
        <div className='nav__square-2'></div>
      </nav>
    </>
  );
};

export default SidebarNewSample;
