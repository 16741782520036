import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import Login from './pages/Login';
import Register from './pages/Register';
import User from './pages/User';
import NotFound from './pages/Page404';
import { useAppSelector } from './store';
import Dashboard from './pages/Dashboard';

// ----------------------------------------------------------------------

const Router = () => {
  const accessToken = useAppSelector((state) => state.auth.accessToken);
  return useRoutes([
    {
      path: '/dashboard',
      element: accessToken ? <DashboardLayout /> : <Navigate to='/login' />,
      children: [
        {
          path: 'app',
          element: <Dashboard />,
        },
        { path: 'user', element: <User /> },
      ],
    },
    {
      path: '/',
      // element: <LogoOnlyLayout />,
      children: [
        { path: '/', element: <Navigate to='/dashboard/app' /> },
        {
          path: 'login',
          element: accessToken ? <Navigate to='/' /> : <Login />,
        },
        {
          path: 'register',
          element: accessToken ? <Navigate to='/' /> : <Register />,
        },
        // {
        //   path: 'forgot',
        //   element: accessToken ? <Navigate to='/' /> : <ForgotPassword />,
        // },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to='/404' /> },
      ],
    },
    { path: '*', element: <Navigate to='/404' replace /> },
  ]);
};

export default Router;
