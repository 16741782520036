// component
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name: string) => (
  <Iconify icon={name} width={22} height={22} />
);

// const sidebarConfig = [
//   {
//     title: "dashboard",
//     path: "/dashboard/app",
//     icon: getIcon("eva:pie-chart-2-fill"),
//   },
//   {
//     title: "user",
//     path: "/dashboard/user",
//     icon: getIcon("eva:people-fill"),
//   },
//   {
//     title: "product",
//     path: "/dashboard/products",
//     icon: getIcon("eva:shopping-bag-fill"),
//   },
//   {
//     title: "blog",
//     path: "/dashboard/blog",
//     icon: getIcon("eva:file-text-fill"),
//   },
//   {
//     title: "login",
//     path: "/login",
//     icon: getIcon("eva:lock-fill"),
//   },
//   {
//     title: "register",
//     path: "/register",
//     icon: getIcon("eva:person-add-fill"),
//   },
//   {
//     title: "Not found",
//     path: "/404",
//     icon: getIcon("eva:alert-triangle-fill"),
//   },
// ];

const sidebarConfig = [
  {
    title: 'Dashboard',
    path: '/dashboard/app',
    icon: '/static/icons/ic_dashboard.png',
  },
  {
    title: 'User Management',
    path: '/dashboard/user',
    icon: '/static/icons/ic_users.png',
  },
  {
    title: 'Messaging',
    path: '/dashboard/products',
    icon: '/static/icons/ic_messaging.png',
  },
  {
    title: 'Reporting',
    path: '/dashboard/blog',
    icon: '/static/icons/ic_media.png',
  },
  {
    title: 'Media',
    path: '/login',
    icon: '/static/icons/ic_media.png',
  },
  {
    title: 'Business',
    path: '/login',
    icon: '/static/icons/ic_business.png',
  },
];

export default sidebarConfig;
