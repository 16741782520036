
// user/profile/contact/save
export interface ProfileContactRequest {
  user_id: number;
  api_token: string;
  phone_number: string;
  email: string;
  business_phone: string;
  business_mobile_phone: string;
  business_email_address: string;
  street1: string;
  street2: string;
  city: string;
  state: string;
  zip_code: string;
  meta_tags: string;
  phone_country_code: string;
}

// user/profile/save
export interface ProfileRequest {
  user_id: number;
  api_token: string;
  first_name: string;
  last_name: string;
  business_phone: string;
  profile_picture: string;
  occupation: string;
  phone_number: string;
  phone_country_code: string;
  background_image: string;
  about_me: string;
  show_profile_picture: number;
}

// user/toggle-phone-number
export interface TogglePhoneNumberRequest {
  user_id: number;
}

// user-feature/update
export interface UpdateFeaturesRequest {
  user_id: number;
  api_token: string;
  ids: number[];
}

// knocard/homepage/save
export interface SetHomePageRequest {
  user_id: number;
  api_token: string;
  homepage: number;
}

interface InitialState {
  isError: boolean;
  isLoading: boolean;
  errorMessage: string | undefined;
}

export const initialState: InitialState = {
  isError: false,
  isLoading: false,
  errorMessage: undefined,
};
