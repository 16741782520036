import { useFormik, Form, FormikProvider } from 'formik';
import {
  Box,
  Button,
  Card,
  Checkbox,
  Chip,
  Container,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import Page from '../components/Page';
import { AppDispatch, useAppSelector } from '../store';
import { useDispatch } from 'react-redux';
import Iconify from '../components/Iconify';
import { Edit } from '@mui/icons-material';
import IOSSwitch from '../components/IOSSwitch';
import { useEffect, useState } from 'react';
import { featureList as FEATURE_LIST } from '../constants';
import { LoadingButton } from '@mui/lab';
import PhoneInput from 'react-phone-input-2';
import { toast } from 'react-toastify';
import { checkEmailAddress, checkUsername } from '../store/register/api';
import { getProfile } from '../store/auth/api';
import { login } from '../store/auth/authSlice';

import _ from 'lodash';
import UploadAssetsModal from '../components/UploadAssetsModal';
import useToggle from '../hooks/useToggle';
import {
  saveProfileContact,
  saveUserProfile,
  setHomePage,
  togglePhoneNumber,
  updateFeatures,
} from '../store/profile/api';
import EditProfileSchema from '../validation/editProfileSchema';
import { isNotEmpty } from '../utils/valid';
import { array } from 'prop-types';

const Dashboard = () => {
  const dispatch = useDispatch<AppDispatch>();

  const { user } = useAppSelector((state) => state?.auth);

  const { toggle, onOpen, onClose } = useToggle();
  const [attachmentArray, setAttachmentArray] = useState([]);

  const [metaTag, setMetaTag] = useState('');
  const [metaTagList, setMetaTagList] = useState<string[]>([]);

  const [selectedFeature, setSelectedFeature] = useState('');
  const [featureList, setFeatureList] = useState(new Map());
  const [featureCheckList, setFeatureCheckList] = useState<any[]>([]);

  const handleMetaTagKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      const _array = [...metaTagList];
      _array.push(metaTag.trim());
      setMetaTagList(_array);

      setMetaTag('');
    }
  };

  const handleChangeFeature = (event: SelectChangeEvent) => {
    setSelectedFeature(event.target.value as string);
  };

  const handleChange =
    (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const _array = [...featureCheckList];
      _array[index] = {
        ...featureCheckList[index],
        ...{ selected: event.target.checked },
      };

      setFeatureCheckList(_array);
    };

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      username: '',
      email: '',
      phoneCountryCode: '',
      mobileNumber: '',
      occupation: '',
      businessLandline: '',
      street1: '',
      street2: '',
      city: '',
      state: '',
      zipCode: '',
      showPhoneNumber: true,
      displayPhoto: true,
    },
    validationSchema: EditProfileSchema,
    onSubmit: async () => {
      if (values.username !== user?.username) {
        const response = await dispatch(
          checkUsername({ username: values.username })
        ).unwrap();
        if (response.data && response.data.status !== 'success') {
          toast.error('Username already taken.');
          setSubmitting(false);
          return;
        }
      }

      if (values.email !== user?.email) {
        const response = await dispatch(
          checkEmailAddress({ email: values.email })
        ).unwrap();
        if (response.data && response.data.status !== 'success') {
          toast.error('Email already taken.');
          setSubmitting(false);
          return;
        }
      }

      // return;

      // const saveUserProfileResponse = await dispatch(
      //   saveProfileContact({
      //     user_id: user?.id ?? 0,
      //     api_token: user?.api_token ?? '',
      //     phone_number: user?.phone_number ?? values.mobileNumber,
      //     phone_country_code:
      //       user?.phone_country_code ?? values.phoneCountryCode,
      //     email: values.email,
      //     business_phone: values.businessLandline,
      //     business_mobile_phone: '',
      //     business_email_address: '',
      //     street1: values.street1,
      //     street2: values.street2,
      //     city: values.city,
      //     state: values.state,
      //     zip_code: values.zipCode,
      //     meta_tags: user?.meta_tags ?? '',
      //   })
      // ).unwrap();
      // if (
      //   saveUserProfileResponse.data &&
      //   saveUserProfileResponse.data.status === 'success'
      // ) {
      // } else if (
      //   saveUserProfileResponse.data.status === 'failed' &&
      //   saveUserProfileResponse.data.message
      // ) {
      //   toast.error(saveUserProfileResponse.data.message);
      //   setSubmitting(false);
      //   return;
      // } else {
      //   toast.error('User updating failed.');
      //   setSubmitting(false);
      //   return;
      // }

      // const response = await dispatch(
      //   saveUserProfile({
      //     user_id: user?.id ?? 0,
      //     api_token: user?.api_token ?? '',
      //     first_name: values.firstName,
      //     last_name: values.lastName,
      //     business_phone: values.businessLandline,
      //     profile_picture: user?.profile_picture ?? '',
      //     occupation: values.occupation,
      //     phone_number: user?.phone_number ?? values.mobileNumber,
      //     phone_country_code:
      //       user?.phone_country_code ?? values.phoneCountryCode,
      //     background_image: user?.knocard?.background_image ?? '',
      //     about_me: user?.about_me ?? '',
      //     show_profile_picture: values.displayPhoto ? 1 : 0,
      //   })
      // ).unwrap();
      // if (response.data && response.data.status === 'success') {
      // } else if (response.data.status === 'failed' && response.data.message) {
      //   toast.error(response.data.message);
      //   setSubmitting(false);
      //   return;
      // } else {
      //   toast.error('User updating failed.');
      //   setSubmitting(false);
      //   return;
      // }

      if ((values.showPhoneNumber ? 1 : 0) !== (user?.show_phone_number ?? 0)) {
        await dispatch(togglePhoneNumber({ user_id: user?.id ?? 0 })).unwrap();
      }

      await dispatch(
        updateFeatures({
          user_id: user?.id ?? 0,
          api_token: user?.api_token ?? '',
          ids: featureCheckList
            .filter((feature) => feature.selected === true)
            .map((feature) => feature.lookupId),
        })
      ).unwrap();

      await dispatch(
        setHomePage({
          user_id: user?.id ?? 0,
          api_token: user?.api_token ?? '',
          homepage: parseInt(selectedFeature),
        })
      ).unwrap();
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    values,
    isSubmitting,
    setSubmitting,
    setFieldValue,
    getFieldProps,
    resetForm,
  } = formik;

  const inputStyle = {
    borderRadius: 1,
    backgroundColor: '#FFFFFF',
    boxShadow:
      '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
  };

  useEffect(() => {
    dispatch(
      getProfile({
        user_id: user?.id ?? 0,
        api_token: user?.api_token ?? '',
        viewer_id: user?.id ?? 0,
      })
    )
      .unwrap()
      .then((response) => {
        if (response.data && response.data.status === 'success') {
          dispatch(
            login({
              accessToken: response?.data?.data?.user?.api_token,
              user: response.data?.data?.user,
            })
          );
        }
      });
  }, []);

  useEffect(() => {
    if (user) {
      values.firstName = user.first_name;
      values.lastName = user.last_name;
      values.username = user.username;
      values.email = user.email;
      values.phoneCountryCode = user.phone_country_code;
      values.mobileNumber = user.phone_number;
      values.occupation = user.occupation;
      values.businessLandline = user.company.business_phone ?? '';
      values.street1 = user.street1 ?? '';
      values.street2 = user.street2 ?? '';
      values.city = user.city ?? '';
      values.state = user.state ?? '';
      values.zipCode = user.zip_code ?? '';
      values.showPhoneNumber = user?.show_phone_number === 1;
      values.displayPhoto = user?.show_profile_picture === 1;

      if (isNotEmpty(user.meta_tags)) {
        setMetaTagList(user.meta_tags.split(','));
      }

      if (user.user_features) {
        const featuresMap = new Map<any, any>(Object.entries(FEATURE_LIST));

        user.user_features.forEach((feature) => {
          if (featuresMap.has(feature.lookup_id.toString())) {
            featuresMap.set(feature.lookup_id.toString(), {
              ...featuresMap.get(feature.lookup_id.toString()),
              ...{ selected: true },
            });
          }
        });

        setFeatureList(featuresMap);

        setSelectedFeature(
          user?.knocard?.homepage ??
            (Array.from(featureList.keys())[0] as string)
        );

        setFeatureCheckList(Array.from(featuresMap.values()));
      }
    }
  }, [user]);

  // return (
  //   <>
  //     {' '}
  //     <Page title='Dashboard | KnoCard' showAppBar={true}>
  //       <Container maxWidth='xl'>
  //         <Typography variant='h4' color={'primary'}>
  //           Dashboard
  //         </Typography>
  //       </Container>
  //     </Page>
  //   </>
  // );

  return (
    <>
      <Page title='Dashboard | KnoCard' showAppBar={true}>
        <Container maxWidth='xl'>
          <FormikProvider value={formik}>
            <Form
              autoComplete='off'
              noValidate
              onSubmit={handleSubmit}
              onReset={() => resetForm()}
            >
              <Grid container direction={'row'} spacing={4}>
                <Grid item md={5} direction={'column'}>
                  <Box sx={{ pb: 2 }}>
                    <Typography variant='h4' color={'primary'}>
                      Dashboard
                    </Typography>
                  </Box>
                  <Stack direction={'row'} spacing={2}>
                    <Card
                      sx={{
                        backgroundImage: `url(${user?.profile_picture})`,
                        scale: 'center',
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        width: '150px',
                        height: '150px',
                        position: 'relative',
                      }}
                    >
                      <IconButton
                        sx={{
                          position: 'absolute',
                          //   left: '95%',
                          //   top: '-9%',
                          // top: '-15px',
                          // right: '-15px',
                          top: 0,
                          right: 0,
                        }}
                        onClick={() => {
                          onOpen();
                        }}
                      >
                        <Iconify icon='el:edit' color='primary.main' />
                      </IconButton>
                    </Card>
                    {isNotEmpty(user?.knocard) &&
                      user?.knocard?.background_image && (
                        <Card
                          sx={{
                            backgroundImage: `url(${
                              (user?.knocard?.background_image ?? '').split(
                                '|'
                              )[2]
                            })`,

                            backgroundSize: 'contain',
                            backgroundRepeat: 'no-repeat',
                            width: '250px',
                            height: '150px',
                          }}
                        >
                          <IconButton
                            sx={{
                              position: 'absolute',
                              top: 0,
                              right: 0,
                            }}
                          >
                            <Iconify icon='el:edit' color='primary.main' />
                          </IconButton>
                        </Card>
                      )}
                  </Stack>
                  <Stack
                    direction={'row'}
                    spacing={1}
                    alignItems={'center'}
                    my={2}
                  >
                    <Typography variant='subtitle1' color={'gray'}>
                      Display Photo
                    </Typography>
                    <FormControlLabel
                      label={''}
                      control={
                        <IOSSwitch
                          sx={{ m: 1 }}
                          checked={values.displayPhoto}
                          onChange={() =>
                            setFieldValue('displayPhoto', !values.displayPhoto)
                          }
                          color={'primary'}
                        />
                      }
                    />
                  </Stack>
                  <Typography variant='subtitle1' color={'gray'}>
                    Meta Tags &nbsp;
                    <Typography variant='body2' component={'span'}>
                      (Pro Members can add upto 10 keywords for better
                      searchability)
                    </Typography>
                  </Typography>
                  <Card sx={{ width: { xs: '100%', md: '60%' } }}>
                    {metaTagList.map((item, index) => (
                      <Chip
                        key={index}
                        label={item}
                        onDelete={() => {}}
                        sx={{ m: 1 }}
                      />
                    ))}
                    <TextField
                      fullWidth
                      value={metaTag}
                      onChange={(e) => setMetaTag(e.target.value)}
                      variant='standard'
                      multiline={true}
                      placeholder='Add a tag'
                      // onKeyDown={handleMetaTagKeyDown}
                      onKeyUp={handleMetaTagKeyDown}
                      sx={{ p: 1 }}
                      InputProps={{ disableUnderline: true }}
                    />
                  </Card>
                  <Typography variant='subtitle2' color={'gray'} my={2}>
                    Select pages you want to display
                  </Typography>
                  <Stack
                    direction={'row'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    sx={{ width: { xs: '100%', md: '60%' } }}
                  >
                    <Typography>Default Home:</Typography>

                    <Select
                      id='demo-simple-select'
                      value={selectedFeature}
                      onChange={handleChangeFeature}
                      sx={{
                        boxShadow: 'none',
                        '.MuiOutlinedInput-notchedOutline': {
                          border: 0,
                        },
                      }}
                    >
                      {Array.from(featureList.values()).map((val: any) => {
                        return (
                          <MenuItem
                            value={val.lookupId}
                            disabled={
                              _.findIndex(featureCheckList, {
                                lookupId: val.lookupId,
                                selected: true,
                              }) < 0
                            }
                          >
                            <Typography variant='subtitle2' color={'primary'}>
                              {val.feature}
                            </Typography>
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </Stack>

                  {featureCheckList.map((feature: any, index) => {
                    return (
                      <Stack
                        direction={'row'}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                        sx={{ width: { xs: '100%', md: '60%' } }}
                      >
                        <Typography>{feature.feature}</Typography>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={feature.selected}
                              onChange={handleChange(index)}
                              name={feature.feature}
                            />
                          }
                          label=''
                          labelPlacement='start'
                        />
                      </Stack>
                    );
                  })}
                </Grid>
                <Grid item>
                  <Box sx={{ pb: 2 }}>
                    <Typography variant='subtitle1' color={'gray'}>
                      Edit Info
                    </Typography>
                  </Box>
                  <Stack spacing={3}>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                      <TextField
                        fullWidth
                        autoComplete='firstName'
                        placeholder='First name'
                        InputProps={{
                          sx: inputStyle,
                          startAdornment: (
                            <InputAdornment position='start'>
                              <PersonOutlineIcon color='primary' />
                            </InputAdornment>
                          ),
                        }}
                        {...getFieldProps('firstName')}
                        error={Boolean(touched.firstName && errors.firstName)}
                        helperText={touched.firstName && errors.firstName}
                      />

                      <TextField
                        fullWidth
                        autoComplete='lastName'
                        placeholder='Last name'
                        InputProps={{
                          sx: inputStyle,
                          startAdornment: (
                            <InputAdornment position='start'>
                              <PersonOutlineIcon color='primary' />
                            </InputAdornment>
                          ),
                        }}
                        {...getFieldProps('lastName')}
                        error={Boolean(touched.lastName && errors.lastName)}
                        helperText={touched.lastName && errors.lastName}
                      />
                    </Stack>

                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                      <TextField
                        fullWidth
                        disabled={true}
                        autoComplete='username'
                        placeholder='Username'
                        InputProps={{
                          sx: inputStyle,
                          startAdornment: (
                            <InputAdornment position='start'>
                              <PersonOutlineIcon color='primary' />
                            </InputAdornment>
                          ),
                        }}
                        {...getFieldProps('username')}
                        error={Boolean(touched.username && errors.username)}
                        helperText={touched.username && errors.username}
                      />
                      <TextField
                        fullWidth
                        autoComplete='email'
                        type='email'
                        placeholder='Email address'
                        InputProps={{
                          sx: inputStyle,
                          startAdornment: (
                            <InputAdornment position='start'>
                              <EmailIcon color='primary' />
                            </InputAdornment>
                          ),
                        }}
                        {...getFieldProps('email')}
                        error={Boolean(touched.email && errors.email)}
                        helperText={touched.email && errors.email}
                      />
                    </Stack>

                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                      <Stack width={'100%'} direction={'row'} spacing={1}>
                        <PhoneInput
                          placeholder=''
                          value={values.phoneCountryCode}
                          onChange={(phone) =>
                            setFieldValue('phoneCountryCode', `+${phone}`)
                          }
                          enableSearch={true}
                          containerStyle={{
                            ...inputStyle,
                            ...{
                              borderRadius: 10,
                              width: '40%',
                              maxHeight: Boolean(
                                touched.mobileNumber && errors.mobileNumber
                              )
                                ? '57px'
                                : 'auto',
                            },
                          }}
                          inputStyle={{
                            ...{
                              width: '100%',
                              borderRadius: 10,
                              cursor: 'default',
                            },
                          }}
                          enableClickOutside={true}
                          countryCodeEditable={false}
                          disableCountryCode={true}
                        />

                        <TextField
                          // fullWidth

                          autoComplete='mobileNumber'
                          placeholder='Mobile Number'
                          InputProps={{
                            sx: inputStyle,
                            startAdornment: (
                              <InputAdornment position='start'>
                                <PhoneIphoneIcon color='primary' />
                              </InputAdornment>
                            ),
                          }}
                          {...getFieldProps('mobileNumber')}
                          error={Boolean(
                            touched.mobileNumber && errors.mobileNumber
                          )}
                          helperText={
                            touched.mobileNumber && errors.mobileNumber
                          }
                        />
                      </Stack>

                      <Stack
                        width={'100%'}
                        direction={'row'}
                        spacing={1}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                        my={2}
                      >
                        <Typography variant='subtitle1' color={'gray'}>
                          Show Personal Cell Number
                        </Typography>
                        <FormControlLabel
                          label={''}
                          control={
                            <IOSSwitch
                              sx={{ m: 1 }}
                              checked={values.showPhoneNumber}
                              onChange={() =>
                                setFieldValue(
                                  'showPhoneNumber',
                                  !values.showPhoneNumber
                                )
                              }
                              color={'primary'}
                            />
                          }
                        />
                      </Stack>
                    </Stack>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                      <TextField
                        fullWidth
                        autoComplete='occupation'
                        placeholder='Occupation'
                        InputProps={{
                          sx: inputStyle,
                          startAdornment: (
                            <InputAdornment position='start'>
                              <PersonOutlineIcon color='primary' />
                            </InputAdornment>
                          ),
                        }}
                        {...getFieldProps('occupation')}
                        error={Boolean(touched.occupation && errors.occupation)}
                        helperText={touched.occupation && errors.occupation}
                      />{' '}
                      <Box
                        width={'100%'}
                        display={{ xs: 'none', sm: 'block' }}
                      />
                    </Stack>

                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                      <TextField
                        fullWidth
                        autoComplete='businessLandline'
                        placeholder='Business Landline'
                        InputProps={{
                          sx: inputStyle,
                          startAdornment: (
                            <InputAdornment position='start'>
                              <PersonOutlineIcon color='primary' />
                            </InputAdornment>
                          ),
                        }}
                        {...getFieldProps('businessLandline')}
                        error={Boolean(
                          touched.businessLandline && errors.businessLandline
                        )}
                        helperText={
                          touched.businessLandline && errors.businessLandline
                        }
                      />{' '}
                      <Box
                        width={'100%'}
                        display={{ xs: 'none', sm: 'block' }}
                      />
                    </Stack>

                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                      <TextField
                        fullWidth
                        autoComplete='street1'
                        placeholder='Street 1'
                        InputProps={{
                          sx: inputStyle,
                          startAdornment: (
                            <InputAdornment position='start'>
                              <PersonOutlineIcon color='primary' />
                            </InputAdornment>
                          ),
                        }}
                        {...getFieldProps('street1')}
                        error={Boolean(touched.street1 && errors.street1)}
                        helperText={touched.street1 && errors.street1}
                      />

                      <TextField
                        fullWidth
                        autoComplete='street2'
                        placeholder='Street 2'
                        InputProps={{
                          sx: inputStyle,
                          startAdornment: (
                            <InputAdornment position='start'>
                              <PersonOutlineIcon color='primary' />
                            </InputAdornment>
                          ),
                        }}
                        {...getFieldProps('street2')}
                        error={Boolean(touched.street2 && errors.street2)}
                        helperText={touched.street2 && errors.street2}
                      />
                    </Stack>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                      <TextField
                        fullWidth
                        autoComplete='city'
                        placeholder='City'
                        InputProps={{
                          sx: inputStyle,
                          startAdornment: (
                            <InputAdornment position='start'>
                              <PersonOutlineIcon color='primary' />
                            </InputAdornment>
                          ),
                        }}
                        {...getFieldProps('city')}
                        error={Boolean(touched.city && errors.city)}
                        helperText={touched.city && errors.city}
                      />

                      <TextField
                        fullWidth
                        autoComplete='state'
                        placeholder='State'
                        InputProps={{
                          sx: inputStyle,
                          startAdornment: (
                            <InputAdornment position='start'>
                              <PersonOutlineIcon color='primary' />
                            </InputAdornment>
                          ),
                        }}
                        {...getFieldProps('state')}
                        error={Boolean(touched.state && errors.state)}
                        helperText={touched.state && errors.state}
                      />
                    </Stack>

                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                      <TextField
                        fullWidth
                        autoComplete='zipCode'
                        placeholder='Zip Code'
                        InputProps={{
                          sx: inputStyle,
                          startAdornment: (
                            <InputAdornment position='start'>
                              <PersonOutlineIcon color='primary' />
                            </InputAdornment>
                          ),
                        }}
                        {...getFieldProps('zipCode')}
                        error={Boolean(touched.zipCode && errors.zipCode)}
                        helperText={touched.zipCode && errors.zipCode}
                      />{' '}
                      <Box
                        width={'100%'}
                        display={{ xs: 'none', sm: 'block' }}
                      />
                    </Stack>

                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                      <Box
                        width={'100%'}
                        display={{ xs: 'none', sm: 'block' }}
                      />
                      <Stack
                        width={'100%'}
                        direction={'row'}
                        justifyContent={'space-around'}
                      >
                        <Button type='reset'>Cancel</Button>
                        <LoadingButton
                          //   fullWidth
                          size='large'
                          type='submit'
                          variant='contained'
                          loading={isSubmitting}
                          // sx={{ width: '95%' }}
                        >
                          Submit
                        </LoadingButton>
                      </Stack>
                    </Stack>
                  </Stack>
                </Grid>
              </Grid>
            </Form>
          </FormikProvider>
          <UploadAssetsModal
            toggle={toggle}
            onClose={onClose}
            setAttachmentArray={setAttachmentArray}
            module={'expense'}
          />
        </Container>
      </Page>
    </>
  );
};
export default Dashboard;
