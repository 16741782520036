import { createSlice } from '@reduxjs/toolkit';
import { authenticateUser, getProfile, logoutUser } from './api';
import { initialState } from './api/interface';
import { removeItem, setItem } from '../../utils/localStorage';
import { config } from '../../utils/utils';

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state, action) => {
      state.accessToken = action.payload.accessToken;
      state.user = action.payload.user;
    },
    logoutUserFromLocal: (state) => {
      state.accessToken = '';
      state.isError = false;
      state.isLoading = false;
      state.user = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(authenticateUser.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(authenticateUser.fulfilled, (state, action) => {
      state.isLoading = false;
      setItem(config.AUTH_KEY, action.payload?.data?.data?.api_token);
      setItem(config.INFO, action.payload.data.data);
    });
    builder.addCase(authenticateUser.rejected, (state, action) => {
      console.log('rejected?', action.payload);
      state.isLoading = false;
      state.isError = true;
    });

    builder.addCase(getProfile.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getProfile.fulfilled, (state, action) => {
      state.isLoading = false;
      setItem(config.AUTH_KEY, action.payload?.data?.data?.user?.api_token);
      setItem(config.INFO, action.payload.data.data.user);
      state.accessToken = action.payload?.data?.data?.user?.api_token;
      state.user = action.payload.data.data.user;
    });
    builder.addCase(getProfile.rejected, (state, action) => {
      console.log('rejected?', action.payload);
      state.isLoading = false;
      state.isError = true;
    });
    builder.addCase(logoutUser.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(logoutUser.fulfilled, (state, _) => {
      state.accessToken = '';
      state.user = undefined;
      state.isLoading = false;
      removeItem(config.INFO);
    });
    builder.addCase(logoutUser.rejected, (state, _) => {
      state.isLoading = false;
      state.isError = true;
    });
  },
});

export const { login, logoutUserFromLocal } = authSlice.actions;
export default authSlice.reducer;
