import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { forwardRef } from 'react';
// material
import { Box } from '@mui/material';
import ResponsiveAppBar from './ResponsiveAppBar';

// ----------------------------------------------------------------------

const Page = forwardRef(
  (
    {
      children,
      title = '',
      showAppBar = false,
      ...other
    }: { children: any; title: any; showAppBar?: any },
    ref
  ) => (
    <Box ref={ref} {...other}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {showAppBar && <ResponsiveAppBar />}
      {children}
    </Box>
  )
);

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  showAppBar: PropTypes.bool,
};

export default Page;
