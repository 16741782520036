import axios from 'axios';
import { config } from '../utils/utils';

export const axiosInstance = axios.create({
  baseURL: `${config.BASE_URL}/api`,
});

const BEARER_TOKEN = config.BEARER_TOKEN;

axiosInstance.interceptors.request.use(
  function (config) {
    config.headers.Authorization = `Bearer ${BEARER_TOKEN}`;
    config.headers['Content-Type'] = 'application/json';
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);
