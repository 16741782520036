import * as Yup from 'yup';

const ChangePasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(6, 'Use 6 characters or more for your password.')
    .required('Password is required. Please add your password.'),
  confirmPassword: Yup.string()
    .oneOf(
      [Yup.ref('password'), null],
      'Password did not match. Please try again.'
    )
    .required('Please type your confirm password.'),
});

export default ChangePasswordSchema;
