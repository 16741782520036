import { axiosInstance } from '../../../client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { Values } from './interface';
import { toastHandler } from '../../../utils/toastHandler';

export const authenticateUser = createAsyncThunk(
  'auth/authenticateUser',
  async (values: Values, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post('user/login', values);
      return response;
    } catch (error: any) {
      toastHandler(error);
      return rejectWithValue(error.message);
    }
  }
);

export const logoutUser = createAsyncThunk(
  'auth/logoutUser',
  async (_, { }) => {
    // feathersClient
    //   .logout()
    //   .then((_) => {
    //     return;
    //   })
    //   .catch((err: any) => {
    //     toastHandler(err);
    //     rejectWithValue(err.message);
    //   });
  }
);

export const getUserInfo = createAsyncThunk(
  'user/singleUser',
  async (
    values: {
      keyword: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.post('user/single-user', values);
      return response;
    } catch (error: any) {
      toastHandler(error);
      return rejectWithValue(error.message);
    }
  }
);

export const getProfile = createAsyncThunk(
  'user/getProfile',
  async (
    values: {
      user_id: number;
      api_token: string;
      viewer_id: number;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.post('user', values);
      return response;
    } catch (error: any) {
      toastHandler(error);
      return rejectWithValue(error.message);
    }
  }
);

export const updatePassword = createAsyncThunk(
  'user/singleUser',
  async (
    values: {
      api_token: string;
      user_id: number;
      password: string;
      confirmed_password: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.post(
        'user/profile/reset-password',
        values
      );
      return response;
    } catch (error: any) {
      toastHandler(error);
      return rejectWithValue(error.message);
    }
  }
);
