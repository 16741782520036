import { axiosInstance } from '../../../client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  ProfileContactRequest,
  ProfileRequest,
  TogglePhoneNumberRequest,
  UpdateFeaturesRequest,
  SetHomePageRequest,
} from './interface';
import { toastHandler } from '../../../utils/toastHandler';

export const saveProfileContact = createAsyncThunk(
  'user/saveProfileContact',
  async (values: ProfileContactRequest, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        'user/profile/contact/save',
        values
      );
      return response;
    } catch (error: any) {
      toastHandler(error);
      return rejectWithValue(error.message);
    }
  }
);

export const saveUserProfile = createAsyncThunk(
  'user/saveUserProfile',
  async (values: ProfileRequest, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post('user/profile/save', values);
      return response;
    } catch (error: any) {
      toastHandler(error);
      return rejectWithValue(error.message);
    }
  }
);

export const togglePhoneNumber = createAsyncThunk(
  'user/togglePhoneNumber',
  async (values: TogglePhoneNumberRequest, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        'user/toggle-phone-number',
        values
      );
      return response;
    } catch (error: any) {
      toastHandler(error);
      return rejectWithValue(error.message);
    }
  }
);

export const updateFeatures = createAsyncThunk(
  'user/updateFeatures',
  async (values: UpdateFeaturesRequest, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post('user-feature/update', values);
      return response;
    } catch (error: any) {
      toastHandler(error);
      return rejectWithValue(error.message);
    }
  }
);

export const setHomePage = createAsyncThunk(
  'user/setHomePage',
  async (values: SetHomePageRequest, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        'knocard/homepage/save',
        values
      );
      return response;
    } catch (error: any) {
      toastHandler(error);
      return rejectWithValue(error.message);
    }
  }
);
