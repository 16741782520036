import * as Yup from 'yup';

const RegisterSchema = Yup.object().shape({
  firstName: Yup.string()
    // .min(2, 'Too Short!')
    // .max(50, 'Too Long!')
    .required('First name is required. Please add your first name.'),
  lastName: Yup.string()
    // .min(2, 'Too Short!')
    // .max(50, 'Too Long!')
    .required('Last name is required. Please add your last name.'),
  username: Yup.string().required(
    'Username is required. Please add your username.'
  ),
  email: Yup.string()
    .email('Email is incorrect format. Please add the correct format of email.')
    .required('Email is required. Please add your email.'),
  mobileNumber: Yup.string()
    .label('Mobile Number')
    .min(6)
    .required('Mobile Number is Required'),
  password: Yup.string()
    .min(6, 'Use 6 characters or more for your password.')
    .required('Password is required. Please add your password.'),
  confirmPassword: Yup.string()
    .oneOf(
      [Yup.ref('password'), null],
      'Password did not match. Please try again.'
    )
    .required('Please type your confirm password.'),
  // agree: Yup.bool().oneOf([true], 'Please check agree before submitting.'),
});
export default RegisterSchema;
