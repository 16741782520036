import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import {
  Link,
  Stack,
  TextField,
  IconButton,
  InputAdornment,
  Typography,
  Button,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// component
import Iconify from '../../../components/Iconify';
import { authenticateUser } from '../../../store/auth/api';
import { login } from '../../../store/auth/authSlice';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../store';
import { toast } from 'react-toastify';
import Person from '@mui/icons-material/Person';
import LockIcon from '@mui/icons-material/Lock';
import LoginSchema from '../../../validation/loginSchema';
import useToggle from '../../../hooks/useToggle';
import ForgotPassword from '../../../pages/ForgotPassword';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [showPassword, setShowPassword] = useState(false);

  const { toggle, onOpen, onClose } = useToggle();

  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    validationSchema: LoginSchema,
    onSubmit: async () => {
      try {
        const response = await dispatch(authenticateUser(values)).unwrap();
        if (response.data.status === 'success') {
          debugger;
          dispatch(
            login({
              accessToken: response?.data?.data?.api_token,
              user: response.data?.data,
            })
          );
        } else {
          toast.error(response?.data?.message);
        }
        setSubmitting(false);
      } catch (error: any) {
        toast.error(error);
      }
    },
  });

  const {
    errors,
    touched,
    values,
    isSubmitting,
    setSubmitting,
    handleSubmit,
    getFieldProps,
  } = formik;
  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <div>
      <FormikProvider value={formik}>
        <Form autoComplete='off' noValidate onSubmit={handleSubmit}>
          <Stack spacing={2}>
            <Typography sx={{ color: 'text.secondary' }}>
              Username or Email address
            </Typography>
            <TextField
              fullWidth
              autoComplete='username'
              type='email'
              {...getFieldProps('username')}
              error={Boolean(touched.username && errors.username)}
              helperText={touched.username && errors.username}
              InputProps={{
                sx: { borderRadius: 30, backgroundColor: '#E8F0FD' },
                startAdornment: (
                  <InputAdornment position='start'>
                    <Person />
                  </InputAdornment>
                ),
              }}
            />

            <Typography sx={{ color: 'text.secondary' }}>
              Enter Password
            </Typography>
            <TextField
              fullWidth
              autoComplete='current-password'
              type={showPassword ? 'text' : 'password'}
              {...getFieldProps('password')}
              InputProps={{
                sx: { borderRadius: 30, mt: 0, backgroundColor: '#E8F0FD' },
                startAdornment: (
                  <InputAdornment position='start'>
                    <LockIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton onClick={handleShowPassword} edge='end'>
                      <Iconify
                        icon={
                          showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'
                        }
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password && errors.password}
            />
          </Stack>

          <Stack
            direction='row'
            alignItems='center'
            justifyContent='center'
            sx={{ my: 2 }}
          >


            <Link
              // component={RouterLink}
              // to='/forgot'
              variant='subtitle2'
              underline='none'
              component={Button}
              onClick={() => {
                if (getFieldProps('username').value.trim().length > 0) {
                  onOpen();
                } else {
                  toast.error('Please add Username.');
                }
              }}
            >
              Forgot Password?
            </Link>
          </Stack>

          <LoadingButton
            fullWidth
            size='large'
            type='submit'
            variant='contained'
            loading={isSubmitting}
            sx={{
              backgroundColor: '#F58227',
              borderRadius: 30,
            }}
          >
            Sign In
          </LoadingButton>
          <Stack alignItems='center' justifyContent='center' sx={{ m: 2 }}>
            <Link
              // component={RouterLink}
              // to={'/register'}
              variant='subtitle2'
              underline='none'
              component={Button}
              onClick={() => navigate('/register')}
            >
              Create new account
            </Link>
          </Stack>
        </Form>
      </FormikProvider>

      <ForgotPassword
        toggle={toggle}
        onClose={onClose}
        username={getFieldProps('username').value.trim()}
      />
    </div>
  );
}
