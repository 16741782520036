import React, { useEffect, useState } from 'react';
import {
  Autocomplete,
  InputAdornment,
  SxProps,
  TextField,
  Theme,
  createFilterOptions,
} from '@mui/material';
import GroupIcon from '@mui/icons-material/Group';
import {
  findSelectAffiliateGroup,
  // searchSelectSite,
} from '../store/selectAffiliateGroup/api';
import { useAppDispatch, useAppSelector } from '../store';

import Loader from './Loader';
import { AffiliateGroup } from '../store/selectAffiliateGroup/api/interface';

interface IProps {
  sx?: SxProps<Theme>;
  onAffiliateGroupSelected: (ag: AffiliateGroup) => void;
}
const AffiliateGroupFilter: React.FC<IProps> = ({
  sx,
  onAffiliateGroupSelected,
}) => {
  // const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { data, isLoading, status } = useAppSelector(
    (state) => state.selectAffiliateGroup
  );

  const [id, setId] = useState(-1);

  useEffect(() => {
    if (status === 'idle') {
      dispatch(findSelectAffiliateGroup());
    }
  }, [status]);

  return (
    <Autocomplete
      loading={isLoading}
      loadingText={<Loader />}
      value={data?.filter((e) => e.id === id)?.[0] || null}
      onChange={(event, newValue) => {
        if (typeof newValue === 'string') {
        } else if (newValue) {
          setId(newValue.id);
          onAffiliateGroupSelected(newValue);
        }
      }}
      onInputChange={(event, newInputValue) => {
        if (!newInputValue) {
          setId(-1);
        }
      }}
      id='controllable-affiliate-group'
      disableClearable
      options={data}
      filterOptions={createFilterOptions({
        matchFrom: 'any',
        stringify: (option) => option.name + option.code,
      })}
      getOptionLabel={(option) => {
        if (typeof option === 'string') {
          return option;
        }
        return option?.name ?? '';
      }}
      fullWidth
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder='Select Group'
          InputProps={{
            ...params.InputProps,
            type: 'search',
            sx,
            startAdornment: (
              <InputAdornment position='start'>
                <GroupIcon color='primary' />
              </InputAdornment>
            ),
          }}
        />
      )}
      ListboxProps={{
        role: 'list-box',
        style: {
          maxHeight: 300,
        },
      }}
    />
  );
};

export default AffiliateGroupFilter;
