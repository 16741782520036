import { useState } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate } from 'react-router-dom';
// material
import {
  Stack,
  TextField,
  IconButton,
  InputAdornment,
  Box,
  FormControlLabel,
  Checkbox,
  Typography,
  Link
} from '@mui/material';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

import { LoadingButton } from '@mui/lab';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css';

// component
import Iconify from '../../../components/Iconify';
import AffiliateGroupFilter from '../../../components/AffiliateGroupFilter';
import AffiliateGroupStateFilter from '../../../components/AffiliateGroupStateFilter';
import { toast } from 'react-toastify';
import RegisterSchema from '../../../validation/registerSchema';
import { AppDispatch, useAppSelector } from '../../../store';
import { useDispatch } from 'react-redux';
import {
  checkEmailAddress,
  checkUsername,
  registerUser,
} from '../../../store/register/api';
import { AffiliateGroup } from '../../../store/selectAffiliateGroup/api/interface';
import { AffiliateGroupState } from '../../../store/selectAffiliateGroupState/api/interface';
import { login } from '../../../store/auth/authSlice';

// ----------------------------------------------------------------------

export default function RegisterForm() {
  const dispatch = useDispatch<AppDispatch>();

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [countryCode, setCountryCode] = useState('+1');
  const [affiliateGroup, setAffiliateGroup] = useState<AffiliateGroup | null>(
    null
  );
  const [AffiliateGroupState, selectAffiliateGroupState] =
    useState<AffiliateGroupState | null>(null);


  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      username: '',
      email: '',
      mobileNumber: '',
      password: '',
      confirmPassword: '',
      agree: false,
    },
    validationSchema: RegisterSchema,
    onSubmit: async () => {
      if (!values.agree) {
        toast.error('Please check agree before submitting.');
        setSubmitting(false);
        return;
      }

      let response = await dispatch(
        checkUsername({ username: values.username })
      ).unwrap();
      if (response.data && response.data.status !== 'success') {
        toast.error('Username already taken.');
        setSubmitting(false);
        return;
      }

      response = await dispatch(
        checkEmailAddress({ email: values.email })
      ).unwrap();
      if (response.data && response.data.status !== 'success') {
        toast.error('Email already taken.');
        setSubmitting(false);
        return;
      }

      response = await dispatch(
        registerUser({
          first_name: values.firstName,
          last_name: values.lastName,
          username: values.username,
          email: values.email,
          password: values.password,
          fcm_token: '',
          referral: '',
          affiliate_group_id: affiliateGroup?.id,
          // leader_name: ,
          group_state_id: AffiliateGroupState?.id,
          phone_number: values.mobileNumber,
          phone_country_code: countryCode,
          firebaseUid: '',
          is_preferred_partner: false,
        })
      ).unwrap();
      if (response.data && response.data.status === 'success') {
        dispatch(
          login({
            accessToken: response?.data?.data?.api_token,
            user: response.data?.data,
          })
        );
      } else if (response.data.status === 'failed' && response.data.message) {
        toast.error(response.data.message);
        setSubmitting(false);
        return;
      } else {
        toast.error('User registration failed.');
        setSubmitting(false);
        return;
      }
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    values,
    isSubmitting,
    setSubmitting,
    getFieldProps,
  } = formik;

  const inputStyle = {
    borderRadius: 1,
    backgroundColor: '#FFFFFF',
    boxShadow:
      '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete='off' noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              autoComplete='firstName'
              placeholder='First name'
              InputProps={{
                sx: inputStyle,
                startAdornment: (
                  <InputAdornment position='start'>
                    <PersonOutlineIcon color='primary' />
                  </InputAdornment>
                ),
              }}
              {...getFieldProps('firstName')}
              error={Boolean(touched.firstName && errors.firstName)}
              helperText={touched.firstName && errors.firstName}
            />

            <TextField
              fullWidth
              autoComplete='lastName'
              placeholder='Last name'
              InputProps={{
                sx: inputStyle,
                startAdornment: (
                  <InputAdornment position='start'>
                    <PersonOutlineIcon color='primary' />
                  </InputAdornment>
                ),
              }}
              {...getFieldProps('lastName')}
              error={Boolean(touched.lastName && errors.lastName)}
              helperText={touched.lastName && errors.lastName}
            />
          </Stack>

          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              autoComplete='username'
              placeholder='Username'
              InputProps={{
                sx: inputStyle,
                startAdornment: (
                  <InputAdornment position='start'>
                    <PersonOutlineIcon color='primary' />
                  </InputAdornment>
                ),
              }}
              {...getFieldProps('username')}
              error={Boolean(touched.username && errors.username)}
              helperText={touched.username && errors.username}
            />
            <TextField
              fullWidth
              autoComplete='email'
              type='email'
              placeholder='Email address'
              InputProps={{
                sx: inputStyle,
                startAdornment: (
                  <InputAdornment position='start'>
                    <EmailIcon color='primary' />
                  </InputAdornment>
                ),
              }}
              {...getFieldProps('email')}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
            />
          </Stack>

          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <Stack
              width={'100%'}
              direction={'row'}
              // sx={{ backgroundColor: 'red' }}
              spacing={1}
            >
              <PhoneInput
                placeholder=''
                value={countryCode}
                onChange={(phone) => setCountryCode(`+${phone}`)}
                country={'us'}
                enableSearch={true}
                containerStyle={{
                  ...inputStyle,
                  ...{
                    borderRadius: 10,
                    width: '40%',
                    maxHeight: Boolean(
                      touched.mobileNumber && errors.mobileNumber
                    )
                      ? '57px'
                      : 'auto',
                  },
                }}
                inputStyle={{
                  ...{ width: '100%', borderRadius: 10, cursor: 'default' },
                }}
                enableClickOutside={true}
                countryCodeEditable={false}
                disableCountryCode={true}
              />

              <TextField
                // fullWidth
                autoComplete='mobileNumber'
                placeholder='Mobile Number'
                InputProps={{
                  sx: inputStyle,
                  startAdornment: (
                    <InputAdornment position='start'>
                      <PhoneIphoneIcon color='primary' />
                    </InputAdornment>
                  ),
                }}
                {...getFieldProps('mobileNumber')}
                error={Boolean(touched.mobileNumber && errors.mobileNumber)}
                helperText={touched.mobileNumber && errors.mobileNumber}
              />
            </Stack>

            <Box width={'100%'} display={{ xs: 'none', sm: 'block' }} />
          </Stack>

          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              type={showPassword ? 'text' : 'password'}
              placeholder='Password'
              {...getFieldProps('password')}
              InputProps={{
                sx: inputStyle,
                startAdornment: (
                  <InputAdornment position='start'>
                    <LockOutlinedIcon color='primary' />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      edge='end'
                      onClick={() => setShowPassword((prev) => !prev)}
                    >
                      <Iconify
                        icon={
                          showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'
                        }
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password && errors.password}
            />
            <TextField
              fullWidth
              type={showConfirmPassword ? 'text' : 'password'}
              placeholder='Confirm Password'
              {...getFieldProps('confirmPassword')}
              InputProps={{
                sx: inputStyle,
                startAdornment: (
                  <InputAdornment position='start'>
                    <LockOutlinedIcon color='primary' />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      edge='end'
                      onClick={() => setShowConfirmPassword((prev) => !prev)}
                    >
                      <Iconify
                        icon={
                          showConfirmPassword
                            ? 'eva:eye-fill'
                            : 'eva:eye-off-fill'
                        }
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={Boolean(touched.confirmPassword && errors.confirmPassword)}
              helperText={touched.confirmPassword && errors.confirmPassword}
            />
          </Stack>

          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <Stack direction={'column'} spacing={1} width={'100%'}>
              <Typography variant='subtitle2' align='center'>
                Are you affiliate with a networking group?
              </Typography>
              <AffiliateGroupFilter
                sx={inputStyle}
                onAffiliateGroupSelected={setAffiliateGroup}
              />
            </Stack>
            <Box width={'100%'} display={{ xs: 'none', sm: 'block' }} />
          </Stack>

          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <AffiliateGroupStateFilter
              sx={inputStyle}
              onAffiliateGroupStateSelected={selectAffiliateGroupState}
            />
            <Box width={'100%'} display={{ xs: 'none', sm: 'block' }} />
          </Stack>

          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <FormControlLabel
              sx={{ width: '100%' }}
              control={<Checkbox {...getFieldProps('agree')} />}
              label={
                <Typography
                  variant='body2'
                  // align='center'
                  sx={{ color: 'text.secondary', width: '100%' }}
                >
                  I have read and accepted&nbsp;
                  <Link
                    underline='none'
                    color='primary'
                    href='https://knocard.com/termsofuse'
                    fontWeight={'fontWeightMedium'}
                  >
                    Terms of Service
                  </Link>
                  &nbsp;and&nbsp;
                  <Link
                    underline='none'
                    color='primary'
                    href='https://knocard.com/privacypolicy'
                    fontWeight={'fontWeightMedium'}
                  >
                    Privacy Policy
                  </Link>
                  ,and give consent to KnoCard to store and personal data that
                  include in my app on their servers
                </Typography>
              }
            />

            <Box width={'100%'} display={{ xs: 'none', sm: 'block' }} />
          </Stack>

          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <Stack width={'100%'}>
              <LoadingButton
                fullWidth
                size='large'
                type='submit'
                variant='contained'
                loading={isSubmitting}
              // sx={{ width: '95%' }}
              >
                Submit
              </LoadingButton>
            </Stack>
            <Box width={'100%'} display={{ xs: 'none', sm: 'block' }} />
          </Stack>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
