import { createSlice } from '@reduxjs/toolkit';
import {
  saveProfileContact,
  saveUserProfile,
  setHomePage,
  togglePhoneNumber,
  updateFeatures,
} from './api';
import { initialState } from './api/interface';

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(saveProfileContact.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(saveProfileContact.fulfilled, (state, _) => {
      state.isLoading = false;
    });
    builder.addCase(saveProfileContact.rejected, (state, action) => {
      console.log('rejected?', action.payload);
      state.isLoading = false;
      state.isError = true;
    });

    builder.addCase(saveUserProfile.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(saveUserProfile.fulfilled, (state, _) => {
      state.isLoading = false;
    });
    builder.addCase(saveUserProfile.rejected, (state, action) => {
      console.log('rejected?', action.payload);
      state.isLoading = false;
      state.isError = true;
    });

    builder.addCase(togglePhoneNumber.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(togglePhoneNumber.fulfilled, (state, _) => {
      debugger;
      state.isLoading = false;
    });
    builder.addCase(togglePhoneNumber.rejected, (state, action) => {
      console.log('rejected?', action.payload);
      state.isLoading = false;
      state.isError = true;
    });

    builder.addCase(updateFeatures.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateFeatures.fulfilled, (state, _) => {
      state.isLoading = false;
    });
    builder.addCase(updateFeatures.rejected, (state, action) => {
      console.log('rejected?', action.payload);
      state.isLoading = false;
      state.isError = true;
    });

    builder.addCase(setHomePage.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(setHomePage.fulfilled, (state, _) => {
      state.isLoading = false;
    });
    builder.addCase(setHomePage.rejected, (state, action) => {
      console.log('rejected?', action.payload);
      state.isLoading = false;
      state.isError = true;
    });
  },
});

export default profileSlice.reducer;
