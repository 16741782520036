import * as Yup from 'yup';

const EditProfileSchema = Yup.object().shape({
  firstName: Yup.string()
    // .min(2, 'Too Short!')
    // .max(50, 'Too Long!')
    .required('First name is required. Please add your first name.'),
  lastName: Yup.string()
    // .min(2, 'Too Short!')
    // .max(50, 'Too Long!')
    .required('Last name is required. Please add your last name.'),
  username: Yup.string().required(
    'Username is required. Please add your username.'
  ),
});
export default EditProfileSchema;
