import { createSlice } from '@reduxjs/toolkit';
import { findSelectAffiliateGroup } from './api';
import { initialState } from './api/interface';

const selectAffiliateGroupSlice = createSlice({
  name: 'selectAffiliateGroup',
  initialState,
  reducers: {
    setStatus: (state, action) => {
      state.status = action.payload.status;
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(findSelectAffiliateGroup.pending, (state) => {
      state.isLoading = true;
      state.status = 'pending';
    });
    builder.addCase(findSelectAffiliateGroup.fulfilled, (state, action) => {
      state.data.push(...action.payload.data.data);
      state.isError = false;
      state.isSuccess = true;
      state.isLoading = false;
      state.status = 'succeeded';
    });
    builder.addCase(findSelectAffiliateGroup.rejected, (state, _) => {
      state.isLoading = false;
      state.isError = true;
      state.isSuccess = false;
      state.status = 'failed';
    });
  },
});

export const { setStatus, reset } = selectAffiliateGroupSlice.actions;
export default selectAffiliateGroupSlice.reducer;
