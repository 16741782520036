// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyDd-Snxni4ZVGNIkHLx4vARw4qxk7H3CkQ',
  authDomain: 'knocard-da3f9.firebaseapp.com',
  databaseURL: 'https://knocard-da3f9.firebaseio.com/',
  projectId: 'knocard-da3f9',
  storageBucket: 'knocard-da3f9.appspot.com',
  messagingSenderId: '455514233841',
  appId: '1:455514233841:web:95b8b2800391047b1b0250',
  measurementId: 'G-RCCSKHT0Z5',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const firebaseAuth = getAuth(app);

// (window as any).recaptchaVerifier = new RecaptchaVerifier(
//   firebaseAuth,
//   'sign-in-button',
//   {
//     size: 'invisible',
//     callback: (response: any) => {
//       // reCAPTCHA solved, allow signInWithPhoneNumber.
//       //   onSignInSubmit();
//     },
//   }
// );
