import { Link as RouterLink } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Card, Stack, Typography, Grid } from '@mui/material';
// layouts
import AuthLayout from '../layouts/AuthLayout';
// components
import Page from '../components/Page';
import { LoginForm } from '../sections/authentication/login';
// import AuthSocial from "../sections/authentication/AuthSocial";

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const SectionStyle = styled('div')(({ theme }) => ({
  // width: '100%',
  width: '100vw',
  maxWidth: 500,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  backgroundImage: 'url(static/illustrations/illustration_login.png)',
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  // margin: theme.spacing(2, 2, 2, 2),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 500,
  margin: 'auto',
  display: 'flex',
  // minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(0, 3),
}));

// ----------------------------------------------------------------------

export default function Login() {
  return (
    <RootStyle title='Login | KnoCard'>
      <Grid
        container
        sx={{ minHeight: '100vh' }}
        alignItems={'center'}
        justifyContent={'center'}
      >
        <Grid item>
          <Grid
            container
            alignItems={'center'}
            justifyContent={'center'}
            position={'relative'}
            display={{ sm: 'flex' }}
          >
            <Grid
              item
              px={3}
              py={1}
              sx={{
                backgroundColor: '#088AC7',
                borderRadius: '40px 0 0 0',
                minWidth: 200,
              }}
            >
              <Typography
                variant={'h6'}
                sx={{ color: 'white' }}
                textAlign={'center'}
              >
                AFFILIATE
              </Typography>
            </Grid>
            <Grid
              item
              px={3}
              py={1}
              sx={{
                backgroundColor: '#F58227',
                borderRadius: '0 40px 0 0',
                minWidth: 200,
              }}
            >
              <Typography
                variant={'h6'}
                sx={{ color: 'white' }}
                textAlign={'center'}
              >
                ENTERPRISE
              </Typography>
            </Grid>
          </Grid>
          <Card
            sx={{
              // boxShadow: '4px 4px 4px 4px #a2a2a2',
              borderLeft: '10px solid #F6F7F9',
              borderRight: '10px solid #F6F7F9',
              borderBottom: '10px solid #F6F7F9',
            }}
          >
            <Grid container display={'flex'} direction={'row'}>
              <Grid
                item
                sx={{
                  display: { xs: 'none', md: 'flex' },
                }}
              >
                <SectionStyle>

                </SectionStyle>
              </Grid>

              <Grid item alignItems={'center'} maxWidth={'sm'}>
                <ContentStyle>
                  <Stack sx={{ my: 2 }}>
                    <Typography
                      variant='h4'
                      gutterBottom
                      sx={{ color: '#F58227' }}
                    >
                      ENTERPRISE LOGIN
                    </Typography>
                    <Typography sx={{ color: 'text.secondary' }}>
                      Please enter your Username/KnoCard Email address and
                      password
                    </Typography>
                  </Stack>
                  {/* <AuthSocial /> */}

                  <LoginForm />
                </ContentStyle>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </RootStyle>
  );
}
