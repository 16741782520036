export const config = {
  // BASE_URL: "https://knocard.com",
  BASE_URL: 'https://staging.knocard.com',
  VBC_URL: 'https://staging.knocard.app',
  BEARER_TOKEN: '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',
  PLAY_STORE_URL:
    'https://play.google.com/store/apps/details?id=com.vbc.knocard.app',
  APP_STORE_URL: 'https://apps.apple.com/us/app/knocard/id1495206208',
  KNOCARD_URL: 'https://staging.knocard.com/',

  AUTH_KEY: 'authkey',
  SECRET_KEY: 'jcR2bsMqNwF',
  INFO: 'info',
};
