import { styled } from '@mui/material/styles';
import Page from '../components/Page';
import OtpInput from 'react-otp-input';
import { useFormik, Form, FormikProvider } from 'formik';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogContent,
  IconButton,
  InputAdornment,
  Link,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useRef, useState } from 'react';
import { AppDispatch } from '../store';
import { useDispatch } from 'react-redux';
import {
  getUserInfo,
  updatePassword,
} from '../store/auth/api';
import { isNotEmpty } from '../utils/valid';
import { toast } from 'react-toastify';
import { firebaseAuth } from '../client/FirebaseClient';
import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
import { toastHandler } from '../utils/toastHandler';
import ChangePasswordSchema from '../validation/changePasswordSchema';
import Iconify from '../components/Iconify';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

interface IProps {
  username: string;
  toggle: boolean;
  onClose: () => void;
}

const ForgotPassword: React.FC<IProps> = ({ username, toggle, onClose }) => {
  const dispatch = useDispatch<AppDispatch>();

  const [otp, setOtp] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [changePassword, setChangePassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const Ref = useRef<any>(null);
  const [timer, setTimer] = useState('00:00');

  const [apiToken, setApiToken] = useState('');
  const [userId, setUserId] = useState(-1);

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  useEffect(() => {
    if (toggle) {
      clearModel();
      firebaseAuth.signOut();
      dispatch(getUserInfo({ keyword: username }))
        .unwrap()
        .then((response) => {
          if (response && response.data.status === 'success') {
            resetTimer();
            if (isNotEmpty(response.data.data.user.phone_number)) {
              setApiToken(response.data.data.user.api_token);
              setUserId(response.data.data.user.id);

              sendCode(
                response.data.data.user.phone_country_code +
                '' +
                response.data.data.user.phone_number
              );
            } else {
              toast.error('Invalid mobile number or user has no mobile number');
            }
          } else {
          }
        });
    }
  }, [toggle]);

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validationSchema: ChangePasswordSchema,
    onSubmit: async () => {
      try {
        const response = await dispatch(
          updatePassword({
            api_token: apiToken,
            user_id: userId,
            password: values.password,
            confirmed_password: values.confirmPassword,
          })
        ).unwrap();
        if (response.data.status === 'success') {
          toast.success('Password changed.');
          onClose();
        } else {
          toast.error(response?.data?.message);
        }
        setSubmitting(false);
      } catch (error: any) {
        toast.error(error);
      }
    },
  });
  const {
    errors,
    touched,
    values,
    isSubmitting,
    setSubmitting,
    handleSubmit,
    getFieldProps,
    resetForm,
  } = formik;

  const clearModel = () => {
    resetForm();
  };

  const sendCode = (mobileNumber: string) => {
    if (timer === '00:00') {
      resetTimer();
    }

    setPhoneNumber(mobileNumber);

    signInWithPhoneNumber(
      firebaseAuth,
      mobileNumber,
      new RecaptchaVerifier(firebaseAuth, 'recaptcha-container', {
        size: 'invisible',
        callback: (response: any) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          // onSignInSubmit();
        },
      })
    )
      .then((confirmationResult) => {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        (window as any).confirmationResult = confirmationResult;
        toast.success('Code successfully send. Please check your phone.');
      })
      .catch((error) => {
        setIsLoading(false);
        // toastHandler(error);
      });
  };

  const onSubmitClicked = () => {
    if ((window as any).confirmationResult) {
      setIsLoading(true);
      (window as any).confirmationResult
        .confirm(otp)
        .then((result: any) => {
          // User signed in successfully.
          // const user = result.user;
          setIsLoading(false);
          setChangePassword(true);
        })
        .catch((error: any) => {
          // User couldn't sign in (bad verification code?)
          setIsLoading(false);
          if (error?.code === 'auth/invalid-verification-code') {
            toast.error('Invalid verification code');
          } else if (error?.code === 'auth/code-expired') {
            toast.error('Code expired');
          } else {
            toastHandler(error);
          }
        });
    }
  };

  // The state for our timer

  const getTimeRemaining = (e: string) => {
    const total = Date.parse(e) - Date.now();
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / 1000 / 60 / 60) % 24);
    return {
      total,
      hours,
      minutes,
      seconds,
    };
  };

  const startTimer = (e: string) => {
    let { total, hours, minutes, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      // update the timer
      // check if less than 10 then we need to
      // add '0' at the beginning of the variable
      setTimer(
        // (hours > 9 ? hours : '0' + hours) +
        // ':' +
        (minutes > 9 ? minutes : '0' + minutes) +
        ':' +
        (seconds > 9 ? seconds : '0' + seconds)
      );
    }
  };

  const clearTimer = (e: string) => {
    setTimer('00:59');

    // If you try to remove this line the
    // updating of timer Variable will be
    // after 1000ms or 1sec
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    Ref.current = id;
  };

  const resetTimer = () => {
    clearTimer(getDeadTime());
  };

  const getDeadTime = () => {
    let deadline = new Date();

    // This is where you need to adjust if
    // you entend to add more time
    deadline.setSeconds(deadline.getSeconds() + 59);
    return deadline.toString();
  };

  return (
    <Dialog
      fullWidth
      open={toggle}
      keepMounted
      onClose={() => {
        onClose();
      }}
      aria-describedby='alert-dialog-slide-description'
    >
      {/* <DialogTitle>VERIFICATION</DialogTitle> */}
      <IconButton
        aria-label='close'
        onClick={onClose}
        sx={{ position: 'absolute', right: 0, zIndex: 100000 }}
      >
        <CloseIcon />
      </IconButton>
      {changePassword ? (
        <DialogContent>
          <FormikProvider value={formik}>
            <Form autoComplete='off' noValidate onSubmit={handleSubmit}>
              <Stack
                direction='column'
                alignItems='center'
                justifyContent='space-between'
                spacing={2}
                my={2}
              >
                <Typography variant='h4'>CHANGE PASSWORD</Typography>
                <TextField
                  fullWidth
                  type={showPassword ? 'text' : 'password'}
                  {...getFieldProps('password')}
                  placeholder='New Password'
                  InputProps={{
                    sx: { borderRadius: 30, mt: 0, backgroundColor: '#E8F0FD' },
                    startAdornment: (
                      <InputAdornment position='start'>
                        <LockOutlinedIcon />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton
                          onClick={() => setShowPassword((prev) => !prev)}
                          edge='end'
                        >
                          <Iconify
                            icon={
                              showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'
                            }
                          />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  error={Boolean(touched.password && errors.password)}
                  helperText={touched.password && errors.password}
                />
                <TextField
                  fullWidth
                  type={showConfirmPassword ? 'text' : 'password'}
                  {...getFieldProps('confirmPassword')}
                  placeholder='Confirm Password'
                  InputProps={{
                    sx: { borderRadius: 30, mt: 0, backgroundColor: '#E8F0FD' },
                    startAdornment: (
                      <InputAdornment position='start'>
                        <LockOutlinedIcon />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton
                          onClick={() =>
                            setShowConfirmPassword((prev) => !prev)
                          }
                          edge='end'
                        >
                          <Iconify
                            icon={
                              showConfirmPassword
                                ? 'eva:eye-fill'
                                : 'eva:eye-off-fill'
                            }
                          />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  error={Boolean(
                    touched.confirmPassword && errors.confirmPassword
                  )}
                  helperText={touched.confirmPassword && errors.confirmPassword}
                />
                <LoadingButton
                  fullWidth
                  size='large'
                  type='submit'
                  variant='contained'
                  loading={isSubmitting}
                  sx={{
                    backgroundColor: '#F58227',
                    borderRadius: 30,
                  }}
                >
                  Change
                </LoadingButton>
              </Stack>
            </Form>
          </FormikProvider>
        </DialogContent>
      ) : (
        <DialogContent>
          <Stack
            direction='column'
            alignItems='center'
            justifyContent='space-between'
            spacing={2}
            my={2}
          >
            <Typography variant='h4'>VERIFICATION</Typography>
            <Typography variant='body1' align='center'>
              Enter verification code that we send you on your phone number.
            </Typography>
            <Typography variant='body1'>
              {isNotEmpty(phoneNumber)
                ? phoneNumber!.substring(
                  0,
                  phoneNumber!.length > 0 ? phoneNumber!.length - 5 : 0
                ) +
                '***' +
                phoneNumber!.substring(
                  phoneNumber!.length > 0 ? phoneNumber!.length - 2 : 0
                )
                : ''}
            </Typography>
            <OtpInput
              value={otp}
              onChange={setOtp}
              numInputs={6}
              renderSeparator={<span>&nbsp;&nbsp;&nbsp;</span>}
              renderInput={(props) => <input {...props} />}
              inputStyle={{ fontSize: '40px' }}
            />
            <LoadingButton
              // fullWidth
              size='large'
              type='submit'
              onClick={onSubmitClicked}
              variant='contained'
              loading={isLoading}
              disabled={otp.length !== 6}
              sx={{ width: { xs: '100%', md: '50%' } }}
            >
              Submit
            </LoadingButton>
            <Typography variant='body1' align='center'>
              If you don't receive a code!{' '}
              <Link
                underline='none'
                onClick={() => sendCode(phoneNumber)}
                component={Button}
                disabled={timer !== '00:00'}
              >
                Resend
              </Link>{' '}
              {timer !== '00:00' ? 'in ' + timer : ''}
            </Typography>
          </Stack>
          <div id='recaptcha-container'></div>
        </DialogContent>
      )}
    </Dialog>
  );
};
export default ForgotPassword;
