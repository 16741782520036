import { Link as RouterLink } from "react-router-dom";
// material
import { styled } from "@mui/material/styles";
import { Box, Button, Typography, Container } from "@mui/material";
// components 
import Page from "../components/Page";

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  display: "flex",
  minHeight: "100%",
  alignItems: "center",
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(10),
}));

// ----------------------------------------------------------------------

export default function Page404() {
  return (
    <RootStyle title="404 Page Not Found | Weigh Bridge">
      <Container>

        <Box sx={{ maxWidth: 480, margin: "auto", textAlign: "center" }}>

          <Typography variant="h3" paragraph>
            Sorry, page not found!
          </Typography>

          <Typography sx={{ color: "text.secondary" }}>
            Sorry, we couldn’t find the page you’re looking for. Perhaps
            you’ve mistyped the URL? Be sure to check your spelling.
          </Typography>


          <Button
            to="/"
            size="large"
            variant="contained"
            component={RouterLink}
            sx={{ mt: 5 }}
          >
            Go to Home
          </Button>
        </Box>

      </Container>
    </RootStyle>
  );
}
