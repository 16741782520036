import { STATUS_TYPE } from '../../../enum';

export interface AffiliateGroupState {
  id: number;
  name: string;
  status: number;
  created_at: Date;
  updated_at: Date;
}

interface InitialState {
  data: AffiliateGroupState[];
  status: STATUS_TYPE;
  isError: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  errorMessage: string;
}

export const initialState: InitialState = {
  data: [],
  status: 'idle',
  isError: false,
  isLoading: false,
  isSuccess: false,
  errorMessage: '',
};
